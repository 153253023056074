import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Chip, Box, Typography, Grid
} from '@mui/material';
import Autocomplete from '@mui/lab/Autocomplete';
import './Activities.css'; // Import the CSS file

const Activities = () => {
  const [activities, setActivities] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [open, setOpen] = useState(false);
  const [materials, setMaterials] = useState([]);
  const [categories, setCategories] = useState([]);
  const [targetWords, setTargetWords] = useState([]);
  const [selectedMaterials, setSelectedMaterials] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedTargetWords, setSelectedTargetWords] = useState([]);
  const [targetSentences, setTargetSentences] = useState({});
  const [filteredTargetWords, setFilteredTargetWords] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [filteredMaterials, setFilteredMaterials] = useState([]);
  const [currentActivity, setCurrentActivity] = useState({
    ID: '',
    Title: '',
    Description: '',
    TargetWords: '',
    Categories: '',
    Materials: '',
    PictureFileName: '',
    Video: '',
    Status: 'Active',
    TargetSentences: {} // New state for target sentences
  });

  const activitiesPerPage = 15; // Define the variable here
  const navigate = useNavigate(); // Use useNavigate hook

  useEffect(() => {
    fetchActivities();
    console.log(process.env.REACT_APP_BACKEND_URL, 'react')
  }, []);

  const fetchActivities = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/activities`);
      setActivities(response.data);
    } catch (error) {
      console.error('There was an error fetching the activities!', error);
    }
  };

  const fetchMaterials = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/populate-materials`);
      const uniqueMaterials = Array.from(new Set(response.data.split('^').filter(item => item)));
      uniqueMaterials.sort((a, b) => a.localeCompare(b));
      setMaterials(uniqueMaterials);
      setFilteredMaterials(uniqueMaterials); // Update filteredMaterials
    } catch (error) {
      console.error('There was an error fetching the materials!', error);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/populate-categories`);
      const uniqueCategories = Array.from(new Set(response.data.split('^').filter(item => item)));
      uniqueCategories.sort((a, b) => a.localeCompare(b));
      setCategories(uniqueCategories);
      setFilteredCategories(uniqueCategories); // Update filteredCategories
    } catch (error) {
      console.error('There was an error fetching the categories!', error);
    }
  };

  const fetchTargetWords = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/populate-target-words`);
      const uniqueTargetWords = Array.from(new Set(response.data.split('^').filter(item => item)));
      uniqueTargetWords.sort((a, b) => a.localeCompare(b));
      setTargetWords(uniqueTargetWords);
      setFilteredTargetWords(uniqueTargetWords); // Update filteredTargetWords
    } catch (error) {
      console.error('There was an error fetching the target words!', error);
    }
  };

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(0); // Reset to first page on new search
  };

  const handleOpenAddActivity = async () => {
    setCurrentActivity({
      ID: '',
      Title: '',
      Description: '',
      TargetWords: '',
      Categories: '',
      Materials: '',
      PictureFileName: '',
      Video: '',
      Status: 'Active',
      TargetSentences: {} // Initialize target sentences
    });
    setSelectedMaterials([]);
    setSelectedCategories([]);
    setSelectedTargetWords([]);
    setTargetSentences({});
    await fetchMaterials();
    await fetchCategories();
    await fetchTargetWords();
    setOpen(true);
  };

  const handleOpen = async (activity) => {
    setCurrentActivity({
      ...activity,
      TargetWords: activity.TargetWords ? activity.TargetWords.split(', ') : [],
      Categories: activity.Categories ? activity.Categories.split(', ') : [],
      Materials: activity.Materials ? activity.Materials.split(', ') : [],
      TargetSentences: activity.TargetSentences || {} // Initialize target sentences
    });
    setSelectedMaterials(activity.Materials ? activity.Materials.split(', ') : []);
    setSelectedCategories(activity.Categories ? activity.Categories.split(', ') : []);
    setSelectedTargetWords(activity.TargetWords ? activity.TargetWords.split(', ') : []);
    setTargetSentences(activity.TargetSentences || {});
    await fetchMaterials();
    await fetchCategories();
    await fetchTargetWords();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setMaterials([]);
    setCategories([]);
    setTargetWords([]);
    setSelectedMaterials([]);
    setSelectedCategories([]);
    setSelectedTargetWords([]);
    setTargetSentences({});
  };


//Activity Submission
const handleSubmit = async (event) => {
  event.preventDefault();
  try {
    const updatedActivity = {
      ...currentActivity,
      Materials: selectedMaterials.join(', '),
      Categories: selectedCategories.join(', '),
      TargetWords: selectedTargetWords.join(', '),
      TargetSentences: targetSentences, // Add target sentences to the activity state
      PictureFileName: currentActivity.PictureFile ? `${Date.now()}-${currentActivity.PictureFile.name}` : ''
    };

    const formData = new FormData();
    Object.keys(updatedActivity).forEach(key => formData.append(key, updatedActivity[key]));

    if (currentActivity.PictureFile) {
      formData.append('Picture', currentActivity.PictureFile);
    }

    await axios.post(`${process.env.REACT_APP_BACKEND_URL}/add-activity`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    fetchActivities();
    handleClose();
  } catch (error) {
    console.error('There was an error saving the activity!', error);
    alert('Error: ' + error.message);
  }
};

//Add Activity
  const handleAddActivity = async (activity) => {
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/add-activity`, activity);
    } catch (error) {
      console.error('There was an error adding the activity!', error);
    }
  };

//Edit Activity
  const handleUpdateActivity = async (activity) => {
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/edit-activity`, activity);
    } catch (error) {
      console.error('There was an error updating the activity!', error);
    }
  };

//Delete Activity
  const handleDelete = async (id) => {
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/delete-activity`, { id });
      fetchActivities();
    } catch (error) {
      console.error('There was an error deleting the activity!', error);
    }
  };

  const handleAddSelected = (setter, selected, value) => {
    if (!selected.includes(value)) {
      setter([...selected, value]);
    }
  };

  const handleRemoveSelected = (setter, selected, value) => {
    setter(selected.filter(item => item !== value));
  };

  const handleSentenceChange = (word, index, value) => {
    setTargetSentences(prevSentences => {
      const newSentences = { ...prevSentences };
      if (!newSentences[word]) {
        newSentences[word] = ['', '', ''];
      }
      newSentences[word][index] = value;
      setCurrentActivity(prev => ({
        ...prev,
        TargetSentences: newSentences
      }));
      return newSentences;
    });
  };

  const filteredActivities = activities.filter(activity => {
    const query = searchQuery.toLowerCase();
    return (
      (activity.ID && activity.ID.toString().includes(query)) ||
      (activity.Title && activity.Title.toLowerCase().includes(query)) ||
      (activity.Description && activity.Description.toLowerCase().includes(query))
    );
  });

  const offset = currentPage * activitiesPerPage;
  const currentActivities = filteredActivities.slice(offset, offset + activitiesPerPage);

  return (
    <div className="container">
     

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Picture</TableCell>
              <TableCell>Video</TableCell>
              <TableCell>Modified By</TableCell>
              <TableCell>Modified Date</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentActivities.map(activity => (
              <TableRow key={activity.ID}>
                <TableCell>{activity.ID}</TableCell>
                <TableCell>{activity.Title}</TableCell>
                <TableCell>{activity.Description}</TableCell>
                <TableCell>{activity.Picture}</TableCell>
                <TableCell>{activity.Video}</TableCell>
                <TableCell>{activity.ModifiedBy}</TableCell>
                <TableCell>{activity.ModifiedDate}</TableCell>
                <TableCell>{activity.Status}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleOpen(activity)}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => handleDelete(activity.ID)}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="header">
        <Button variant="contained" color="primary" onClick={handleOpenAddActivity}>
          Add Activity
        </Button>
        <Button variant="contained" color="primary" onClick={() => navigate('/')}>
          Back to Home
        </Button>
        <div className="search-bar">
          <TextField
            label="Search activities"
            variant="outlined"
            value={searchQuery}
            onChange={handleSearch}
            fullWidth
          />
        </div>
        <h1>Activities</h1>
        <nav>
          <ul className="nav-tabs">
            <li>
              <Link to="/">Home</Link>
            </li>
          </ul>
        </nav>
      </div>
      <ReactPaginate
        previousLabel={'Previous'}
        nextLabel={'Next'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={Math.ceil(filteredActivities.length / activitiesPerPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
      />

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth classes={{ paper: 'dialog-paper' }}>
        <DialogTitle>{currentActivity.ID ? 'Edit Activity' : 'Add Activity'}</DialogTitle>
        <DialogContent className="dialog-content">
          <DialogContentText>
            Please fill in the details of the activity.
          </DialogContentText>
          <form onSubmit={handleSubmit}>
            <TextField
              margin="dense"
              label="Title"
              type="text"
              fullWidth
              value={currentActivity.Title}
              onChange={(e) => setCurrentActivity({ ...currentActivity, Title: e.target.value })}
              required
            />
            <TextField
              margin="dense"
              label="Description"
              type="text"
              fullWidth
              value={currentActivity.Description}
              onChange={(e) => setCurrentActivity({ ...currentActivity, Description: e.target.value })}
              required
            />
            <Box>
              <Autocomplete
                multiple
                options={filteredTargetWords}
                getOptionLabel={(option) => option}
                value={selectedTargetWords}
                onChange={(event, value) => setSelectedTargetWords(value)}
                filterSelectedOptions
                disableCloseOnSelect
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      label={option}
                      {...getTagProps({ index })}
                      className="chip-blue"
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Target Words"
                    placeholder="Add target words"
                    margin="dense"
                    fullWidth
                  />
                )}
              />
            </Box>
            <Box mt={2}>
              {selectedTargetWords.map(word => (
                <Box key={word} mb={2}>
                  <Typography variant="h6">{word}</Typography>
                  <Grid container spacing={2}>
                    {[0, 1, 2, 3 , 4].map(index => (
                      <Grid item xs={12} sm={4} key={index}>
                        <TextField
                          variant="outlined"
                          label={`Sentence ${index + 1}`}
                          fullWidth
                          value={targetSentences[word] ? targetSentences[word][index] : ''}
                          onChange={(e) => handleSentenceChange(word, index, e.target.value)}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              ))}
            </Box>
            <Box>
              <Autocomplete
                multiple
                options={filteredCategories}
                getOptionLabel={(option) => option}
                value={selectedCategories}
                onChange={(event, value) => setSelectedCategories(value)}
                filterSelectedOptions
                disableCloseOnSelect
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      label={option}
                      {...getTagProps({ index })}
                      className="chip-purple"
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Categories"
                    placeholder="Add categories"
                    margin="dense"
                    fullWidth
                  />
                )}
              />
            </Box>
            <Box>
              <Autocomplete
                multiple
                options={filteredMaterials}
                getOptionLabel={(option) => option}
                value={selectedMaterials}
                onChange={(event, value) => setSelectedMaterials(value)}
                filterSelectedOptions
                disableCloseOnSelect
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      label={option}
                      {...getTagProps({ index })}
                      className="chip-green"
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Materials"
                    placeholder="Add materials"
                    margin="dense"
                    fullWidth
                  />
                )}
              />
            </Box>
            <TextField
              margin="dense"
              label="Video"
              type="text"
              fullWidth
              value={currentActivity.Video}
              onChange={(e) => setCurrentActivity({ ...currentActivity, Video: e.target.value })}
            />
            <TextField
              margin="dense"
              label="Picture FileName"
              type="text"
              fullWidth
              value={currentActivity.PictureFileName}
              onChange={(e) => setCurrentActivity({ ...currentActivity, PictureFileName: e.target.value })}
            />
            <TextField
              margin="dense"
              label="Status"
              type="text"
              fullWidth
              value={currentActivity.Status}
              onChange={(e) => setCurrentActivity({ ...currentActivity, Status: e.target.value })}
              required
            />
             <input
    type="file"
    onChange={(e) => setCurrentActivity({ ...currentActivity, PictureFile: e.target.files[0] })}
  />
            <DialogActions className="dialog-actions">
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                {currentActivity.ID ? 'Update' : 'Add'}
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );

};

export default Activities;

