import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import './Footer.css'; // Import the CSS file
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';

const Footer = () => {
  return (
    <Box className="footer-container">
      <Box className="footer-content">
        <Box className="contact-info">
          <Typography variant="h6">Contact Information</Typography>
          <Typography>Email: l4toddler@gmail.com</Typography>
          <Typography>Phone: +1 520 621 1644</Typography>
          <Typography>Address: 1131 E 2nd St Tucson, AZ 85721</Typography>
        </Box>
        <Box className="social-media-links">
          <Typography variant="h6">Follow Us</Typography>
          <Link href="https://www.facebook.com/ArizonaSLHS/" target="_blank" rel="noopener" className="social-link">
            <FacebookIcon/>
          </Link>
          <Link href="https://twitter.com/ArizonaSLHS" target="_blank" rel="noopener" className="social-link">
            <XIcon/>
          </Link>
          <Link href="https://www.instagram.com/arizonaslhs/" target="_blank" rel="noopener" className="social-link">
            <InstagramIcon/>
          </Link>
          <Link href="https://www.youtube.com/user/ArizonaSLHS" target="_blank" rel="noopener" className="social-link">
            <YouTubeIcon/>
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
