import React, { useState, useEffect, useRef } from 'react';
import './Chatbot.css';
import avatar from '../../data/avatar2.jpg'; // Adjust the path to be relative

const OPENAI_API_KEY = process.env.REACT_APP_OPEN_AI_KEY

function Chatbot() {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [lastGeneratedImagePrompt, setLastGeneratedImagePrompt] = useState('');
  const messagesEndRef = useRef(null);
  const conversationHistory = useRef([]);

  const initialSystemMessage = {
    role: 'system',
    content: `You are a knowledgeable assistant specialized in helping parents and therapists working with children with delayed learning. You are part of Learning Vault, a website dedicated to teaching target words using pictures and sentences. When answering questions, provide useful tips and strategies for engaging children with delayed learning, explain how to use pictures and sentences effectively, and offer support on various techniques and tools available on Learning Vault. Always respond with empathy and encouragement. Keep it short.`
  };

  const toggleChat = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      scrollToBottom();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const lowerInput = input.toLowerCase();
    if (lowerInput.includes('image') ) {
      await generateImage(input);
    } else if (lowerInput.startsWith('make it') || lowerInput.startsWith('can you make it')) {
      await modifyImage(input);
    } else {
      await generateResponse();
    }
  };

  const generateResponse = async () => {
    let inputText = input.trim();

    if (!inputText) {
      alert("Please enter a message.");
      return;
    }

    addMessageToChat(inputText, 'user');
    conversationHistory.current.push({ role: 'user', content: inputText });
    setInput('');
    setLoading(true);

    try {
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 20000); // Set timeout to 20 seconds

      // Clone conversation history and append initial system message
      const messagesToSend = [initialSystemMessage, ...conversationHistory.current];

      let response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${OPENAI_API_KEY}`
        },
        body: JSON.stringify({
          model: 'gpt-4',
          messages: messagesToSend,
          max_tokens: 300,
          temperature: 0.7
        }),
        signal: controller.signal
      });

      clearTimeout(timeoutId);
      setLoading(false);

      if (!response.ok) {
        throw new Error(`Server responded with status ${response.status}`);
      }

      let responseData = await response.json();
      console.log('Response Data:', responseData);

      let botResponse = responseData.choices && responseData.choices[0] && responseData.choices[0].message && responseData.choices[0].message.content;
      if (botResponse) {
        addMessageToChat(botResponse, 'bot');
        conversationHistory.current.push({ role: 'assistant', content: botResponse });
      } else {
        console.log('No valid response received.');
        addMessageToChat("No valid response received. Please try again.", 'system');
      }
    } catch (error) {
      setLoading(false);
      if (error.name === 'AbortError') {
        console.error('Error: Request timed out.');
        addMessageToChat("Error: Request timed out. Please try again.", 'system');
      } else {
        console.error('Error:', error.message);
        addMessageToChat(`Error: ${error.message}`, 'system');
      }
    }
  };

  const generateImage = async (inputText) => {
    addMessageToChat(inputText, 'user');
    setInput('');
    setLoading(true);

    try {
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 20000); // Set timeout to 20 seconds

      const prompt = inputText.replace(/create an image of|can you make me an image of|make me an image of/, '').trim() + ', Create a realistic and child-friendly image, capturing it clearly and simply. The image should feature only the [subject], without any background or additional elements. Use soft, gentle lines and warm colors to make the [subject] approachable and inviting for children. Avoid any complex details or exaggerated features, ensuring the [subject] is easy to recognize and understand. The overall style should be friendly and welcoming, appealing to a young audience while staying true to life.';

      let response = await fetch('https://api.openai.com/v1/images/generations', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${OPENAI_API_KEY}`
        },
        body: JSON.stringify({
          prompt: prompt,
          n: 1,
          size: '1024x1024',
          model: "dall-e-3"
        }),
        signal: controller.signal
      });

      clearTimeout(timeoutId);
      setLoading(false);

      if (!response.ok) {
        throw new Error(`Server responded with status ${response.status}`);
      }

      let responseData = await response.json();
      console.log('Response Data:', responseData);

      if (responseData.data && responseData.data.length > 0) {
        const imageUrl = responseData.data[0].url;
        addMessageToChat(imageUrl, 'bot', false, true);
        setLastGeneratedImagePrompt(prompt);
      } else {
        console.log('No valid image received.');
        addMessageToChat("No valid image received. Please try again.", 'system');
      }
    } catch (error) {
      setLoading(false);
      if (error.name === 'AbortError') {
        console.error('Error: Request timed out.');
        addMessageToChat("Error: Request timed out. Please try again.", 'system');
      } else {
        console.error('Error:', error.message);
        addMessageToChat(`Error: ${error.message}`, 'system');
      }
    }
  };

  const modifyImage = async (inputText) => {
    addMessageToChat(inputText, 'user');
    setInput('');
    setLoading(true);

    try {
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 20000); // Set timeout to 20 seconds

      const modification = inputText.replace(/make it|can you make it/, '').trim();
      const prompt = `${lastGeneratedImagePrompt}, ${modification}`;

      let response = await fetch('https://api.openai.com/v1/images/generations', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${OPENAI_API_KEY}`
        },
        body: JSON.stringify({
          prompt: prompt,
          n: 1,
          size: '1024x1024',
          model: 'dall-e-3'
        }),
        signal: controller.signal
      });

      clearTimeout(timeoutId);
      setLoading(false);

      if (!response.ok) {
        throw new Error(`Server responded with status ${response.status}`);
      }

      let responseData = await response.json();
      console.log('Response Data:', responseData);

      if (responseData.data && responseData.data.length > 0) {
        const imageUrl = responseData.data[0].url;
        addMessageToChat(imageUrl, 'bot', false, true);
      } else {
        console.log('No valid image received.');
        addMessageToChat("No valid image received. Please try again.", 'system');
      }
    } catch (error) {
      setLoading(false);
      if (error.name === 'AbortError') {
        console.error('Error: Request timed out.');
        addMessageToChat("Error: Request timed out. Please try again.", 'system');
      } else {
        console.error('Error:', error.message);
        addMessageToChat(`Error: ${error.message}`, 'system');
      }
    }
  };

  const addMessageToChat = (message, sender, isHtml = false, isImage = false) => {
    setMessages((prevMessages) => {
      const updatedMessages = [...prevMessages, { sender, text: message, isHtml, isImage }];
      scrollToBottom();
      return updatedMessages;
    });
  };

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <div className="chatbot-wrapper">
      <div className={`chatbot-container ${isOpen ? 'open' : ''}`}>
        <div className="chatbot-header" onClick={toggleChat}>
          <span>Learning Vault Chatbot</span>
          <span className="chatbot-toggle">✖</span>
        </div>
        {isOpen && (
          <div className="chatbot-body">
            <div className="chatbot-messages" id="output">
              {messages.map((message, index) => (
                <div key={index} className={`chatbot-message ${message.sender}`}>
                  {message.sender === 'bot' && <img src={avatar} alt="Bot Avatar" />}
                  <div className={`chatbot-message-content ${message.isImage ? 'chatbot-message-dalle' : ''}`}>
                    {message.isImage ? (
                      <img src={message.text} alt="Generated" className="chatbot-message-dalle" />
                    ) : (
                      <p>{message.text}</p>
                    )}
                  </div>
                </div>
              ))}
              {loading && (
                <div className="chatbot-message bot">
                  <img src={avatar} alt="Bot Avatar" />
                  <div className="chatbot-message-content typing-indicator">
                    <span></span><span></span><span></span>
                  </div>
                </div>
              )}
              <div ref={messagesEndRef} />
            </div>
            <div className="chatbot-input-container">
              <textarea
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyDown={handleKeyDown}
                placeholder="Type a message..."
                id="inputText"
              ></textarea>
              <button onClick={handleSubmit} id="submitButton"><strong>&#x2b90;</strong></button>
            </div>
          </div>
        )}
      </div>
      <div className="chatbot-icon" onClick={toggleChat}>
        <div className="chat-bubble-icon"></div>
      </div>
    </div>
  );
}

export default Chatbot;
