import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AppBar, Tabs, Tab, Box, TextField, IconButton, Autocomplete, Menu, MenuItem } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import axios from 'axios';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import './AppBar.css';
import logo from '../../data/logo.png';

const CustomAppBar = () => {
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [error, setError] = useState(null); // State for handling errors
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (query.length > 2) {
      const fetchSuggestions = async () => {
        try {
          const response = await axios.get(`http://localhost:3000/search-activities`, {
            params: { search: query }
          });
          setSuggestions(response.data);
          setError(null); // Reset error state on successful fetch
        } catch (error) {
          console.error('There was an error fetching the suggestions!', error);
          setError('There was an error fetching the suggestions. Please try again later.');
        }
      };

      fetchSuggestions();
    } else {
      setSuggestions([]);
      setError(null); // Reset error state when query is too short
    }
  }, [query]);

  const handleSearchChange = (event, value) => {
    setQuery(value);
  };

  const handleSearchSelect = async (event, value) => {
    if (value) {
      try {
        const response = await axios.get(`http://localhost:3000/activity-details/${value.ID}`);
        const { activityDetails, targetSentences } = response.data;
        navigate(`/activity-details/${value.ID}`, { state: { activityDetails, targetSentences } });
      } catch (error) {
        console.error('There was an error fetching the activity details!', error);
        setError('There was an error fetching the activity details. Please try again later.');
      }
    }
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleGoogleLoginSuccess = async (response) => {
    const token = response.credential;
    try {
      const res = await axios.post('http://localhost:3001/api/auth/google', { token });
      console.log('Login Success:', res.data);
      // Handle the successful login here, e.g., save the user info in the state or context
    } catch (error) {
      console.error('There was an error during login:', error);
    }
  };

  const handleGoogleLoginFailure = (response) => {
    console.error('Login Failed:', response);
  };

  return ( //Replace "YOUR_GOOGLE_CLIENT_ID" with actual ID
    <GoogleOAuthProvider clientId="YOUR_GOOGLE_CLIENT_ID">
      <AppBar position="fixed" className="app-bar">
        <Box className="app-bar-content">
          <Box className="logo">
            <Link to="/">
              <img src={logo} alt="Vault Learning Logo" className="logo-image" />
            </Link>
          </Box>
          <Box className="search-icon-container" title="Advanced Search">
            <IconButton component={Link} to="/search" color="inherit" className="search-icon-button">
              <SearchIcon />
            </IconButton>
          </Box>
          <Box className="search-bar-container">
            <Autocomplete
              freeSolo
              options={suggestions}
              getOptionLabel={(option) => `${option.Title} (${option.ID})`}
              onInputChange={handleSearchChange}
              onChange={handleSearchSelect}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Search by Activity ID or Title"
                  className="search-bar no-outline"
                  inputProps={{
                    ...params.inputProps,
                    maxLength: 100,
                    style: { backgroundColor: 'white' }
                  }}
                />
              )}
            />
          </Box>
          <Tabs className="tabs">
            <Box className="tab">
              <Tab label="Home" component={Link} to="/" />
            </Box>
            <Box className="tab">
              <Tab label="Search" component={Link} to="/search" />
            </Box>
            <Box className="tab">
              <Tab label="Parents" component={Link} to="/parents" />
            </Box>
            <Box className="tab">
              <Tab label="Interventionist" component={Link} to="/interventionist" />
            </Box>
            <Box className="tab" onMouseEnter={handleMenuOpen} onMouseLeave={handleMenuClose}>
              <Tab label="Activity Builder" />
              <Menu
                id="activity-builder-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                MenuListProps={{ onMouseLeave: handleMenuClose }}
              >
                <MenuItem component={Link} to="/activities" onClick={handleMenuClose}>Activity Builder</MenuItem>
                <MenuItem component={Link} to="/manage-words" onClick={handleMenuClose}>Manage Words</MenuItem>
              </Menu>
            </Box>
            <Box className="tab">
              <Tab label="About" component={Link} to="/about" />
            </Box>
          </Tabs>
          <Box className="google-login">
            <GoogleLogin
              onSuccess={handleGoogleLoginSuccess}
              onFailure={handleGoogleLoginFailure}
              buttonText="Login with Google"
              cookiePolicy={'single_host_origin'}
            />
          </Box>
        </Box>
        {error && <Box className="error-message">{error}</Box>}
      </AppBar>
    </GoogleOAuthProvider>
  );
};

export default CustomAppBar;
