// src/components/Interventionist/Interventionist.js

import React from 'react';
import { Box, Typography } from '@mui/material';

const Interventionist = () => {
  return (
    <Box 
      sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh',
        textAlign: 'center',
        backgroundColor: '#f4f4f4'
      }}
    >
      <Typography variant="h2" gutterBottom>
        Oops!
      </Typography>
      <Typography variant="h5">
        This site is still under construction.
      </Typography>
    </Box>
  );
};

export default Interventionist;
