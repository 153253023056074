import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  CircularProgress,
} from '@mui/material';
import Autocomplete from '@mui/lab/Autocomplete';
import './ManageWords.css';

const ManageWords = () => {
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [selectedWord, setSelectedWord] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [wordExists, setWordExists] = useState(false);
  const [wordType, setWordType] = useState(''); // State to track the type of the word

  useEffect(() => {
    if (query.length > 2) {
      const fetchSuggestions = async () => {
        try {
          setLoading(true);
          setError('');
          const [categoriesResponse, materialsResponse, targetWordsResponse] = await Promise.all([
            axios.get('http://localhost:3000/populate-categories'),
            axios.get('http://localhost:3000/populate-materials'),
            axios.get('http://localhost:3000/populate-target-words')
          ]);

          const categories = categoriesResponse.data.split('^').filter(item => item);
          const materials = materialsResponse.data.split('^').filter(item => item);
          const targetWords = targetWordsResponse.data.split('^').filter(item => item);

          const allWords = [
            ...categories.map(word => ({ word, type: 'category' })),
            ...materials.map(word => ({ word, type: 'material' })),
            ...targetWords.map(word => ({ word, type: 'targetWord' }))
          ];

          const filteredSuggestions = allWords.filter(entry => entry.word.toLowerCase().includes(query.toLowerCase()));

          setSuggestions(filteredSuggestions);
          setWordExists(filteredSuggestions.length > 0);
        } catch (error) {
          setError('There was an error fetching the words. Please try again later.');
        } finally {
          setLoading(false);
        }
      };

      fetchSuggestions();
    } else {
      setSuggestions([]);
    }
  }, [query]);

  const handleSearchChange = (event, value) => {
    setQuery(value);
  };

  const handleSearchSelect = (event, value) => {
    if (value) {
      setSelectedWord(value.word);
      setWordType(value.type);
      setDialogOpen(true);
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedWord(null);
    setWordType('');
  };

  const handleAddWord = async () => {
    try {
      await axios.post('http://localhost:3000/add-word', { type: wordType || 'category', word: query }); // Adjust type as necessary
      setDialogOpen(false);
      setQuery('');
      alert('Word added successfully.');
    } catch (error) {
      setError('There was an error adding the word. Please try again later.');
    }
  };

  const handleEditWord = async () => {
    try {
      await axios.post('http://localhost:3000/edit-word', { type: wordType, oldWord: selectedWord, newWord: query });
      setDialogOpen(false);
      setQuery('');
      alert('Word edited successfully.');
    } catch (error) {
      setError('There was an error editing the word. Please try again later.');
    }
  };

  const handleDeleteWord = async () => {
    try {
      await axios.post('http://localhost:3000/delete-word', { type: wordType, word: selectedWord });
      setDialogOpen(false);
      setQuery('');
      alert('Word deleted successfully.');
    } catch (error) {
      setError('There was an error deleting the word. Please try again later.');
    }
  };

  return (
    <Box className="manage-words-container">
      <Typography variant="h4" gutterBottom>Manage Words</Typography>
      <Box className="search-bar">
        <Autocomplete
          freeSolo
          options={suggestions}
          getOptionLabel={(option) => option.word}
          onInputChange={handleSearchChange}
          onChange={handleSearchSelect}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Search categories, materials, or target words"
              fullWidth
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </Box>
      {error && <Typography className="error-message">{error}</Typography>}
      <Dialog open={dialogOpen} onClose={handleDialogClose} classes={{ paper: 'dialog-paper' }}>
        <DialogTitle>{selectedWord ? 'Edit Word' : 'Add Word'}</DialogTitle>
        <DialogContent className="dialog-content">
          <DialogContentText>
            {selectedWord ? 'Would you like to edit or delete this word?' : (wordExists ? 'No words found. Would you like to add it?' : 'Word not found. Would you like to add it?')}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="word"
            label="Word"
            type="text"
            fullWidth
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          {selectedWord ? (
            <>
              <Button onClick={handleEditWord} color="primary">Edit</Button>
              <Button onClick={handleDeleteWord} color="secondary">Delete</Button>
            </>
          ) : (
            <Button onClick={handleAddWord} color="primary">Add</Button>
          )}
          <Button onClick={handleDialogClose} color="primary">Cancel</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ManageWords;
