import React from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './components/Home/Home';
import Activities from './components/Activities/Activities';
import ActivityDetails from './components/ActivityDetails/ActivityDetails';
import Search from './components/Search/Search';
import Chatbot from './components/ChatBot/Chatbot';
import ManageWords from './components/ManageWords/ManageWords'; // Import the ManageWords component
import Layout from './components/Layout/Layout';
import Footer from './components/Footer/Footer';
import About from './components/About/About';
import Parents from './components/Parents/Parents';
import Interventionist from './components/Interventionist/Interventionist';

function App() {
  return (
    <div className="App">
      <Layout>
        <Routes>       
          <Route path="/" element={<Home />} />
          <Route path="/activities" element={<Activities />} />
          <Route path="/activityDetails" element={<ActivityDetails />} />
          <Route path="/search" element={<Search />} />
          <Route path="/activity-details/:activityId" element={<ActivityDetails />} />
          <Route path="/manage-words" element={<ManageWords />} /> {/* Add this line */}
          <Route path="/About" element={<About/>} />
          <Route path="/Parents" element={<Parents/>} />
          <Route path="/interventionist" element={<Interventionist/>} />
        </Routes>
          <Chatbot />
      </Layout>
    </div>
  );
}

export default App;
