import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextField, Box, Typography, Button, CircularProgress, Chip, Grid } from '@mui/material';
import Autocomplete from '@mui/lab/Autocomplete';
import { useNavigate } from 'react-router-dom';
import Card from '../Card/Card';
import './Search.css';

const Search = () => {
  const [query, setQuery] = useState({
    categories: [],
    materials: [],
    targetWords: [],
  });
  const [loading, setLoading] = useState(false);
  const [activities, setActivities] = useState([]);
  const [categories, setCategories] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [targetWords, setTargetWords] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchCategories();
    fetchMaterials();
    fetchTargetWords();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/populate-categories`);
      const uniqueCategories = Array.from(new Set(response.data.split('^').filter(item => item)));
      uniqueCategories.sort((a, b) => a.localeCompare(b));
      setCategories(uniqueCategories);
    } catch (error) {
      console.error('There was an error fetching the categories!', error);
    }
  };

  const fetchMaterials = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/populate-materials`);
      const uniqueMaterials = Array.from(new Set(response.data.split('^').filter(item => item)));
      uniqueMaterials.sort((a, b) => a.localeCompare(b));
      setMaterials(uniqueMaterials);
    } catch (error) {
      console.error('There was an error fetching the materials!', error);
    }
  };

  const fetchTargetWords = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/populate-target-words`);
      const uniqueTargetWords = Array.from(new Set(response.data.split('^').filter(item => item)));
      uniqueTargetWords.sort((a, b) => a.localeCompare(b));
      setTargetWords(uniqueTargetWords);
    } catch (error) {
      console.error('There was an error fetching the target words!', error);
    }
  };

  const handleSearch = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/search-activities`, {
        params: {
          categories: query.categories.join('^'),
          materials: query.materials.join('^'),
          targetWords: query.targetWords.join('^'),
        },
      });
      setActivities(response.data);
    } catch (error) {
      console.error('There was an error fetching the data!', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCardClick = async (activity) => {
    const activityId = activity.ID;

    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/activity-details/${activityId}`);
      const { activityDetails, targetSentences } = response.data;
      navigate(`/activity-details/${activityId}`, { state: { activityDetails, targetSentences } });
    } catch (error) {
      console.error('There was an error fetching the activity details!', error);
    }
  };

  return (
    <Box className="search-container">
      <Button onClick={() => navigate('/')} className="back-button">Back</Button>
      <Typography variant="h4" gutterBottom>Search Activities</Typography>
      <Box className="search-bar">
        <Box className="search-box autocomplete-box">
          <Autocomplete
            multiple
            options={categories}
            getOptionLabel={(option) => option}
            value={query.categories}
            onChange={(event, newValue) => setQuery({ ...query, categories: newValue })}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  label={option}
                  {...getTagProps({ index })}
                  className="chip"
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search by Categories"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Box>
        <Box className="search-box autocomplete-box">
          <Autocomplete
            multiple
            options={materials}
            getOptionLabel={(option) => option}
            value={query.materials}
            onChange={(event, newValue) => setQuery({ ...query, materials: newValue })}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  label={option}
                  {...getTagProps({ index })}
                  className="chip"
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search by Materials"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Box>
        <Box className="search-box autocomplete-box">
          <Autocomplete
            multiple
            options={targetWords}
            getOptionLabel={(option) => option}
            value={query.targetWords}
            onChange={(event, newValue) => setQuery({ ...query, targetWords: newValue })}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  label={option}
                  {...getTagProps({ index })}
                  className="chip"
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search by Target Words"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSearch}
          disabled={loading}
        >
          {loading ? 'Loading...' : 'Search'}
        </Button>
        {loading && <CircularProgress />}
      </Box>
      <Grid container spacing={3} className="grid-container">
        {activities.map((activity) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={activity.ID}>
            <Card
              image={`https://learning-vault-images.s3.amazonaws.com/${activity.Picture}`}
              title={activity.Title}
              subtitle={activity.Description}
              onClick={() => handleCardClick(activity)}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Search;
