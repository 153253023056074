// src/components/Layout/Layout.js
import React from 'react';
import CustomAppBar from '../AppBar/AppBar'; // Ensure the correct import path

const Layout = ({ children }) => {
  return (
    <div>
      <CustomAppBar />
      <div style={{ paddingTop: '64px', paddingBottom: '60px' }}> {/* Adjust padding to account for fixed AppBar and Footer */}
        {children}
      </div>
    </div>
  );
};

export default Layout;
