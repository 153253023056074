import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, Button, FormControl, InputLabel, Select, MenuItem, IconButton, Popover, Grid, CardMedia, List, ListItem, ListItemText, Card, CardContent } from '@mui/material';
import { VolumeUp as VolumeUpIcon, Close as CloseIcon } from '@mui/icons-material';
import axios from 'axios';
import './Home.css'; // Import the CSS file
import CustomAppBar from '../AppBar/AppBar'; // Correct the import path
import Footer from '../Footer/Footer';
import parentsCard from '../../data/parentsCard.jpg';
import interventionistCard from '../../data/interventionistCard.jpg';
import Airplane from '../../data/Airplane.jpg';

const Home = () => {
  const [ttsEnabled, setTtsEnabled] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const audioRef = useRef(null);
  const openaiApiKey = process.env.REACT_APP_OPEN_AI_KEY
  /*const [highlightedText, setHighlightedText] = useState(''); */
  const [translatedText, setTranslatedText] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);

  const languages = [
    { code: 'en', name: 'English' },
    { code: 'es', name: 'Spanish' },
    { code: 'fr', name: 'French' },
    { code: 'de', name: 'German' },
    { code: 'vi', name: 'Vietnamese' },
    { code: 'ja', name: 'Japanese' },
    { code: 'ko', name: 'Korean' },
    // Add more languages as needed
  ];

  const handleTranslation = async (text, targetLanguage) => {
    try {
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-4',
          messages: [
            { role: 'system', content: 'You are a helpful assistant that translates text.' },
            { role: 'user', content: `Translate the following text to ${targetLanguage}: ${text}` },
          ],
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${openaiApiKey}`,
          },
        }
      );
      return response.data.choices[0].message.content.trim();
    } catch (error) {
      console.error('Error translating text:', error);
      return text; // Fallback to original text if translation fails
    }
  };

  const handleTextToSpeech = async (text, button) => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current = null;
      button.style.backgroundColor = '';
      return;
    }

    try {
      const translatedText = selectedLanguage === 'en' ? text : await handleTranslation(text, selectedLanguage);
      const response = await axios.post(
        'https://api.openai.com/v1/audio/speech',
        {
          model: 'tts-1',
          voice: 'alloy',
          input: translatedText,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${openaiApiKey}`,
          },
          responseType: 'arraybuffer',
        }
      );

      const blob = new Blob([response.data], { type: 'audio/mp3' });
      const url = URL.createObjectURL(blob);
      const newAudio = new Audio(url);
      audioRef.current = newAudio;

      newAudio.play();
      button.style.backgroundColor = "#ffa500"; // Highlight the button when speaking

      newAudio.onended = () => {
        button.style.backgroundColor = ""; // Remove highlight when done speaking
        audioRef.current = null;
      };
    } catch (error) {
      console.error('Error generating or playing speech:', error);
    }
  };

  useEffect(() => {
    if (ttsEnabled) {
      initializeSpeechButtons();
    } else {
      removeSpeechButtons();
    }
  }, [ttsEnabled]);

  useEffect(() => {
    document.addEventListener('mouseup', handleTextHighlight);
    return () => {
      document.removeEventListener('mouseup', handleTextHighlight);
    };
  }, [ttsEnabled, selectedLanguage]);

  const initializeSpeechButtons = () => {
    const tags = document.querySelectorAll('p, a, h1, h2, h3');
    tags.forEach(tag => {
      // Exclude elements without text content
      if (tag.innerText.trim().length === 0 || tag.classList.contains('exclude-speech')) {
        return;
      }

      const container = document.createElement('div');
      container.classList.add('speech-container');

      const clonedTag = tag.cloneNode(true);

      const button = document.createElement('button');
      button.innerHTML = '<i class="fas fa-volume-up"></i>'; // VolumeUpIcon HTML
      button.classList.add('speech-button');
      button.addEventListener('click', async () => {
        const text = button.previousSibling.textContent.trim();
        handleTextToSpeech(text, button);
      });

      container.appendChild(clonedTag);
      container.appendChild(button);

      tag.parentNode.replaceChild(container, tag);
    });
  };

  const removeSpeechButtons = () => {
    const containers = document.querySelectorAll('.speech-container');
    containers.forEach(container => {
      const clonedTag = container.querySelector('p, a, h1, h2, h3').cloneNode(true);
      container.parentNode.replaceChild(clonedTag, container);
    });

    const cardButtons = document.querySelectorAll('.speech-button');
    cardButtons.forEach(button => button.remove());
  };

  const handleTextHighlight = async (event) => {
    const selectedText = window.getSelection().toString().trim();
    if (selectedText && ttsEnabled) {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
        return;
      }

      try {
        const translatedText = selectedLanguage === 'en' ? selectedText : await handleTranslation(selectedText, selectedLanguage);
        const response = await axios.post(
          'https://api.openai.com/v1/audio/speech',
          {
            model: 'tts-1',
            voice: 'alloy',
            input: translatedText,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${openaiApiKey}`,
            },
            responseType: 'arraybuffer',
          }
        );

        const blob = new Blob([response.data], { type: 'audio/mp3' });
        const url = URL.createObjectURL(blob);
        const newAudio = new Audio(url);
        audioRef.current = newAudio;

        newAudio.play();

        newAudio.onended = () => {
          document.querySelectorAll('.highlighted-text').forEach(element => {
            element.classList.remove('highlighted-text');
          });
          audioRef.current = null;
        };

        // Highlight the selected text
        const range = window.getSelection().getRangeAt(0);
        const span = document.createElement('span');
        span.className = 'highlighted-text';
        range.surroundContents(span);
        // New additions
        const translation = await handleTranslation(selectedText, selectedLanguage);
        setTranslatedText(translation);
        setAnchorEl(event.target);

      } catch (error) {
        console.error('Error generating or playing speech:', error);
      }
    } else {
      setAnchorEl(null);

      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
      }
      document.querySelectorAll('.highlighted-text').forEach(element => {
        element.classList.remove('highlighted-text');
      });
    }
  };

  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
  };

  const closeTranslate = () => {
    setSelectedLanguage('en');
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const popoverId = open ? 'simple-popover' : undefined;

  return (
    <Box className="home-container">
      <CustomAppBar /> {/* Use the new AppBar component */}
      <Box className="content-container">
        <Typography variant="h2" className="centered-title">
        </Typography>
        <Box mt={4} textAlign="center">
          <Box 
          className = "tss-button-container" // Assign class name for the container
          sx={{ position: 'fixed', bottom: '20px', left: '50%', transform: 'translateX(-50%)', zIndex: 1002 }}>
            <Button
              className = "tts-toggle-button" // Assign class name to the TTS button for CSS editing
              variant="contained"
              color={ttsEnabled ? "secondary" : "primary"}
              onClick={() => setTtsEnabled(!ttsEnabled)}
              style={{ marginRight: '10px' }}
            >
              {ttsEnabled ? 'Disable Text-to-Speech' : 'Enable Text-to-Speech'}
            </Button>
            {ttsEnabled && (
              <FormControl variant="outlined" sx={{ minWidth: 120, marginLeft: '10px' }} className="language-select">
                <InputLabel>Language</InputLabel>
                <Select value={selectedLanguage} onChange={handleLanguageChange} label="Language">
                  {languages.map((lang) => (
                    <MenuItem key={lang.code} value={lang.code}>
                      {lang.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {ttsEnabled && (
              <IconButton
                className="tss-close-button" // Assign class for closing action
                onClick={closeTranslate}
                style={{
                  marginLeft: '10px',
                  bgcolor: 'primary.main',
                  color: 'white',
                }}
              >
                <CloseIcon />
              </IconButton>
            )}
          </Box>
        </Box>
        {
        <Box className="cards-container">
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} sm={6} md={2}>
              <Card>
                <CardMedia
                  component="img"
                  image={parentsCard}
                  alt="Parents interacting with child in a learning activity"
                  style={{ height: '300px', objectFit: 'cover', objectPosition: 'top' }} // Ensure the image covers the container and is aligned to the top
                />
                <CardContent>
                  <Typography variant="h5" component="div">
                    PARENTS GET STARTED
                  </Typography>
                  <List dense>
                    <ListItem>
                      <ListItemText primary="- Interactive Activities" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="- Progress Tracking" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="- Resource Library" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="- Community Support" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="- Scheduling and Reminders" />
                    </ListItem>
                  </List>
                  {ttsEnabled && (
                    <IconButton
                      aria-label="speak"
                      onClick={() => handleTextToSpeech('PARENTS GET STARTED. Interactive Activities. Progress Tracking. Resource Library. Community Support. Scheduling and Reminders.', this)}
                    >
                      <VolumeUpIcon />
                    </IconButton>
                  )}
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <Card>
                <CardMedia
                  component="img"
                  image={interventionistCard}
                  alt="Interventionist alongside parents teaching child learning activities"
                  style={{ height: '300px', objectFit: 'cover', objectPosition: 'top' }} // Ensure the image covers the container and is aligned to the top
                />
                <CardContent>
                  <Typography variant="h5" component="div">
                    INTERVENTIONIST GET STARTED
                  </Typography>
                  <List dense>
                    <ListItem>
                      <ListItemText primary="- Parent Collaboration" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="- Activity Builder" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="- Resource Library" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="- Best Practices and Guidelines" />
                    </ListItem>
                  </List>
                  {ttsEnabled && (
                    <IconButton
                      aria-label="speak"
                      onClick={() => handleTextToSpeech('INTERVENTIONIST GET STARTED. Parent Collaboration. Activity Builder. Resource Library. Best Practices and Guidelines.', this)}
                    >
                      <VolumeUpIcon />
                    </IconButton>
                  )}
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <Card>
                <CardMedia
                  component="img"
                  image={Airplane}
                  alt="Drawing of an Airplane"
                  style={{ height: '300px', objectFit: 'cover', objectPosition: 'left' }} // Ensure the image covers the container and is aligned to the top
                />
                <CardContent>
                  <Typography variant="h5" component="div">
                    Constructive Play
                  </Typography>
                  <List dense>
                    <ListItem>
                      <ListItemText primary="- See related Activities" />
                    </ListItem>
                  </List>
                  {ttsEnabled && (
                    <IconButton
                      aria-label="speak"
                      onClick={() => handleTextToSpeech('Constructive Play. See related Activities.', this)}
                    >
                      <VolumeUpIcon />
                    </IconButton>
                  )}
                </CardContent>
              </Card>
            </Grid>
            {/* <Grid item xs={12} sm={6} md={2}>
              <Card>
                <CardMedia
                  component="img"
                  image={Balloons}
                  alt="Balloons of many colors floating in the air."
                  style={{ height: '300px', objectFit: 'cover', objectPosition: 'top' }} // Ensure the image covers the container and is aligned to the top
                />
                <CardContent>
                  <Typography variant="h5" component="div">
                    GROSS MOTOR
                  </Typography>
                  <List dense>
                    <ListItem>
                      <ListItemText primary="- Blow up balloons and play with them" />
                    </ListItem>
                  </List>
                  {ttsEnabled && (
                    <IconButton
                      aria-label="speak"
                      onClick={() => handleTextToSpeech('GROSS MOTOR. Blow up balloons and play with them.', this)}
                    >
                      <VolumeUpIcon />
                    </IconButton>
                  )}
                </CardContent>
              </Card>
            </Grid>
            */}
            {/* 
            <Grid item xs={12} sm={6} md={2}>
              <Card>
                <CardMedia
                  component="img"
                  image={Cat}
                  alt="Image of a gray with black stripes cat."
                  style={{ height: '300px', objectFit: 'cover', objectPosition: 'top' }} // Ensure the image covers the container and is aligned to the top
                />
                <CardContent>
                  <Typography variant="h5" component="div">
                    BOOKS
                  </Typography>
                  <List dense>
                    <ListItem>
                      <ListItemText primary="- Read a book and talk about cats" />
                    </ListItem>
                  </List>
                  {ttsEnabled && (
                    <IconButton
                      aria-label="speak"
                      onClick={() => handleTextToSpeech('BOOKS. Read a book and talk about cats.', this)}
                    >
                      <VolumeUpIcon /> 
                    </IconButton>
                  )}
                </CardContent>
              </Card>
            </Grid>
            */}
          </Grid>
        </Box>
        }
        <Footer />
      </Box>
      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box p={2}>
          <Typography variant="body1">{translatedText}</Typography>
        </Box>
      </Popover>
    </Box>
  );
};

export default Home;
