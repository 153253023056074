import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box, Typography, Card, CardContent, Chip, Grid, Button, Popover, FormControl, InputLabel, Select, MenuItem, IconButton } from '@mui/material';
import axios from 'axios';
import { Close as CloseIcon } from '@mui/icons-material';
import '@fortawesome/fontawesome-free/css/all.css'; 
import './ActivityDetails.css';

const ActivityDetails = () => {
  const { activityId } = useParams();
  const navigate = useNavigate();
  const [activityDetails, setActivityDetails] = useState(null);
  const [targetSentences, setTargetSentences] = useState({});
  const [selectedTargetWord, setSelectedTargetWord] = useState('');
  const [text, setText] = useState('');
  const [translateOpen, setTranslateOpen] = useState(false);
  const [ttsEnabled, setTtsEnabled] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const audioRef = useRef(null);
  const [highlightedText, setHighlightedText] = useState('');
  const [translatedText, setTranslatedText] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);

  const openaiApiKey = 'sk-proj-xWfAnCnkJ2GZ7G9XRsNST3BlbkFJSyRRFXIKHMMcTroB3wnJ'; // Replace with your actual key

  const handleTextHighlight = async (event) => {
    const selectedText = window.getSelection().toString().trim();
    if (selectedText && ttsEnabled) {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
        return;
      }

      try {
        const translatedText = selectedLanguage === ' ' ? selectedText : await handleTranslation(selectedText, selectedLanguage);
        const response = await axios.post(
          'https://api.openai.com/v1/audio/speech',
          {
            model: 'tts-1',
            voice: 'alloy',
            input: translatedText,
            language: selectedLanguage,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${openaiApiKey}`,
            },
            responseType: 'arraybuffer',
          }
        );

        const blob = new Blob([response.data], { type: 'audio/mp3' });
        const url = URL.createObjectURL(blob);
        const newAudio = new Audio(url);
        audioRef.current = newAudio;

        newAudio.play();

        newAudio.onended = () => {
          document.querySelectorAll('.highlighted-text').forEach(element => {
            element.classList.remove('highlighted-text');
          });
          audioRef.current = null;
        };

        // Highlight the selected text
        const range = window.getSelection().getRangeAt(0);
        const span = document.createElement('span');
        span.className = 'highlighted-text';
        range.surroundContents(span);

        const translation = await handleTranslation(selectedText, selectedLanguage);
        setTranslatedText(translation);
        setAnchorEl(event.target);

      } catch (error) {
        console.error('Error generating or playing speech:', error);
      }
    } else {
      setAnchorEl(null);

      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
      }
      document.querySelectorAll('.highlighted-text').forEach(element => {
        element.classList.remove('highlighted-text');
      });
    }
  };

  const removeSpeechButtons = useCallback(() => {
    const containers = document.querySelectorAll('.speech-container');
    containers.forEach(container => {
      const clonedTag = container.querySelector('p, a, h1, h2, h3').cloneNode(true);
      container.parentNode.replaceChild(clonedTag, container);
    });

    const cardButtons = document.querySelectorAll('.speech-button');
    cardButtons.forEach(button => button.remove());
  }, []);

  useEffect(() => {
    fetchActivityDetails();
  }, [activityId]);

  useEffect(() => {
    if (ttsEnabled) {
      initializeSpeechButtons();
    } else {
      removeSpeechButtons();
    }
  }, [ttsEnabled, removeSpeechButtons]);

  useEffect(() => {
    document.addEventListener('mouseup', handleTextHighlight);
    return () => {
      document.removeEventListener('mouseup', handleTextHighlight);
    };
  }, [ttsEnabled, selectedLanguage]);

  const fetchActivityDetails = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/activity-details/${activityId}`);
      const { activityDetails, targetSentences } = response.data;
      setActivityDetails(activityDetails);
      setTargetSentences(targetSentences);
      setSelectedTargetWord(activityDetails.TargetWords.split('^')[0] || '');
    } catch (error) {
      console.error('There was an error fetching the activity details!', error);
    }
  };

  if (!activityDetails) {
    return <Typography variant="h6">Loading...</Typography>;
  }

  const uniqueCategories = activityDetails.Categories ? [...new Set(activityDetails.Categories.split('^'))] : [];
  const uniqueMaterials = activityDetails.Materials ? [...new Set(activityDetails.Materials.split('^'))] : [];
  const uniqueTargetWords = activityDetails.TargetWords ? [...new Set(activityDetails.TargetWords.split('^'))] : [];

  const boldTargetWord = (sentence, targetWord) => {
    const regex = new RegExp(`(${targetWord})`, 'gi');
    const newSentence = sentence.replace(regex, '<strong>$1</strong>');
    return { __html: newSentence };
  };

  const languages = [
    { code: 'en', name: 'English' },
    { code: 'es', name: 'Spanish' },
    { code: 'fr', name: 'French' },
    { code: 'de', name: 'German' },
    { code: 'vi', name: 'Vietnamese' },
    { code: 'ja', name: 'Japanese' },
    { code: 'ko', name: 'Korean' },
    // Add more languages as needed
  ];

  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  const handleTranslation = async (text, targetLanguage) => {
    try {
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-4',
          messages: [
            { role: 'system', content: 'You are a helpful assistant that translates text.' },
            { role: 'user', content: `Translate the following text to ${targetLanguage}: ${text}` },
          ],
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${openaiApiKey}`,
          },
        }
      );
      return response.data.choices[0].message.content.trim();
    } catch (error) {
      console.error('Error translating text:', error);
      return text; // Fallback to original text if translation fails
    }
  };

  const handleTextToSpeech = async (text, button) => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current = null;
      button.style.backgroundColor = '';
      return;
    }

    try {
      const translatedText = selectedLanguage === 'en' ? text : await handleTranslation(text, selectedLanguage);
      const response = await axios.post(
        'https://api.openai.com/v1/audio/speech',
        {
          model: 'tts-1',
          voice: 'alloy',
          input: translatedText,
          language: selectedLanguage,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${openaiApiKey}`,
          },
          responseType: 'arraybuffer',
        }
      );

      const blob = new Blob([response.data], { type: 'audio/mp3' });
      const url = URL.createObjectURL(blob);
      const newAudio = new Audio(url);
      audioRef.current = newAudio;

      newAudio.play();
      button.style.backgroundColor = "#ffa500"; // Highlight the button when speaking

      newAudio.onended = () => {
        button.style.backgroundColor = ""; // Remove highlight when done speaking
        audioRef.current = null;
      };
    } catch (error) {
      console.error('Error generating or playing speech:', error);
    }
  };

  const initializeSpeechButtons = () => {
    const tags = document.querySelectorAll('p, a, h1, h2, h3');
    tags.forEach(tag => {
      // Exclude elements without text content
      if (tag.innerText.trim().length === 0 || tag.classList.contains('exclude-speech')) {
        return;
      }

      const container = document.createElement('div');
      container.classList.add('speech-container');

      const clonedTag = tag.cloneNode(true);

      const button = document.createElement('button');
      button.innerHTML = '<i class="fas fa-volume-up"></i>'; // VolumeUpIcon HTML
      button.classList.add('speech-button');
      button.addEventListener('click', async () => {
        const text = button.previousSibling.textContent.trim();
        handleTextToSpeech(text, button);
      });

      container.appendChild(clonedTag);
      container.appendChild(button);

      tag.parentNode.replaceChild(container, tag);
    });

    // Add VolumeUpIcon to existing card buttons
    const cardButtons = document.querySelectorAll('.card-content-text button');
    cardButtons.forEach(button => {
      button.style.display = 'inline-block'; // Ensure the button is visible

      const volumeUpButton = document.createElement('button');
      volumeUpButton.innerHTML = '<i class="fas fa-volume-up"></i>';
      volumeUpButton.classList.add('speech-button');
      volumeUpButton.addEventListener('click', () => {
        const text = button.previousSibling.textContent.trim();
        handleTextToSpeech(text, volumeUpButton);
      });

      // Insert VolumeUpIcon before the existing speaker icon button
      button.parentNode.insertBefore(volumeUpButton, button);
    });
  };

  const toggleTtsEnabled = () => {
    setTtsEnabled(!ttsEnabled);
  };

  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
  };

  const toggleTranslate = () => {
    setTranslateOpen(!translateOpen);
    if (!translateOpen) {
      setSelectedLanguage('en'); // Reset language to English when closing the translate panel
    }
  };

  const closeTranslate = () => {
    setTranslateOpen(false);
    setSelectedLanguage('en');
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const popoverId = open ? 'simple-popover' : undefined;

  // Extract YouTube video ID from URL
  const getYouTubeEmbedUrl = (url) => {
    const regex = /^.*(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|v\/|.*\?v=)|youtu\.be\/)([^"&?\/\s]{11})$/;
    const match = url.match(regex);
    return match ? `https://www.youtube.com/embed/${match[1]}` : '';
  };

  return (
    <Box className="details-container" mt={4}>
      <Button className="back-button" variant="contained" color="primary" onClick={() => navigate(-1)}>
        Back
      </Button>
      <Card variant="outlined" className="activity-card" mt={2}>
        <CardContent>
          <Typography variant="h5">{activityDetails.Title}</Typography>
          <Typography variant="body1" gutterBottom>{activityDetails.Description}</Typography>
          {activityDetails.Picture && <img src={activityDetails.Picture} alt={activityDetails.Title} className="activity-image" />}
          {/* Embed YouTube video */}
          {activityDetails.Video && (
            <Box mt={2}>
              <Typography variant="h6">Video</Typography>
              <Box
                component="iframe"
                src={getYouTubeEmbedUrl(activityDetails.Video)}
                title="YouTube Video"
                width="100%"
                height="315"
                frameBorder="0"
                allowFullScreen
                sx={{ borderRadius: '4px' }}
              />
            </Box>
          )}
          <Box mt={2} className="target-words-container">
            <Typography variant="h6">Target Words</Typography>
            <Grid container spacing={1}>
              {uniqueTargetWords.map((word, index) => (
                <Grid item key={index}>
                  <Chip
                    label={word}
                    onClick={() => setSelectedTargetWord(word)}
                    className="chip-target"
                    color={selectedTargetWord === word ? 'primary' : 'default'}
                  />
                </Grid>
              ))}
            </Grid>
            {selectedTargetWord && targetSentences[selectedTargetWord] && (
              <Box mt={2}>
                <Typography variant="h6">Sentences</Typography>
                <ul className="sentence-list">
                  {targetSentences[selectedTargetWord].split('^').map((sentence, index) => (
                    <li key={index}>
                     <Typography variant="body2" dangerouslySetInnerHTML={boldTargetWord(sentence, selectedTargetWord)} />
                    </li>
                  ))}
                </ul>
              </Box>
            )}
          </Box>
          <Grid container spacing={2} mt={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="h6">Categories</Typography>
              <Box display="flex" flexWrap="wrap">
                {uniqueCategories.map((category, index) => (
                  <Chip key={index} label={category} className="chip chip-category" />
                ))}
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6">Materials</Typography>
              <Box display="flex" flexWrap="wrap">
                {uniqueMaterials.map((material, index) => (
                  <Chip key={index} label={material} className="chip chip-materials" />
                ))}
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Box mt={4} textAlign="center">
        <Box sx={{ position: 'fixed', bottom: '20px', left: '50%', transform: 'translateX(-50%)' }}>
          <Button
            variant="contained"
            color={ttsEnabled ? 'secondary' : 'primary'}
            onClick={toggleTtsEnabled}
            style={{ marginRight: '10px' }}
          >
            {ttsEnabled ? 'Disable Text-to-Speech' : 'Enable Text-to-Speech'}
          </Button>
          {ttsEnabled && (
            <>
              <FormControl variant="outlined" sx={{ minWidth: 120, marginLeft: '10px' }}>
                <InputLabel>Language</InputLabel>
                <Select value={selectedLanguage} onChange={handleLanguageChange} label="Language">
                  {languages.map((lang) => (
                    <MenuItem key={lang.code} value={lang.code}>
                      {lang.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <IconButton
                onClick={closeTranslate}
                style={{
                  marginLeft: '10px',
                  padding: '0',
                  minWidth: 'auto',
                  backgroundColor: 'transparent',
                  color: 'gray',
                }}
              >
                <CloseIcon />
              </IconButton>
            </>
          )}
        </Box>
      </Box>
      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box p={2}>
          <Typography variant="body1">{translatedText}</Typography>
        </Box>
      </Popover>
    </Box>
  );
};

export default ActivityDetails;