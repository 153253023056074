// src/components/About/About.js

import React from 'react';
import { Box, Typography, List, ListItem, ListItemText } from '@mui/material';
import CustomAppBar from '../AppBar/AppBar';
import Footer from '../Footer/Footer'; // Reuse the footer if necessary
import './About.css'; // Create a CSS file for specific styling

const About = () => {
  return (
    <Box className="about-container">
      <CustomAppBar />
      <Box className="about-content">
        <Typography variant="h2" className="about-title">
          About Vault Learning
        </Typography>
        <Typography variant="body1" className="about-description">
          Vault Learning is dedicated to providing interactive and engaging learning resources for parents, interventionists, and educators. Our platform offers a range of activities, progress tracking tools, and community support to enhance learning experiences.
        </Typography>

        <Typography variant="h4" className="about-section-title">
          Our Features
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="- Interactive Activities" />
          </ListItem>
          <ListItem> 
            <ListItemText primary="- Activity Builder" />
          </ListItem>
        </List>
        <Typography variant="h4" className="about-future-update-title">
            Future Updates
        </Typography>
        <List>
        <ListItem>
            <ListItemText primary="- Progress Tracking" />
          </ListItem>
          <ListItem>
            <ListItemText primary="- Resource Library" />
          </ListItem>
          <ListItem>
            <ListItemText primary="- Community Support" />
          </ListItem>
          <ListItem>
            <ListItemText primary="- Scheduling and Reminders" />
          </ListItem>
        </List>

        <Typography variant="h4" className="about-section-title">
          Contact Us
        </Typography>
        <Typography variant="body1">
          If you have any questions, feel free to reach out to us below.
        </Typography>
      </Box>
      <Footer />
    </Box>
  );
};

export default About;
