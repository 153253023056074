import React from 'react';
import './Card.css';

const Card = ({ image, title, subtitle, name, onClick }) => {
  return (
    <div className="card" onClick={onClick}>
      <div className="card-inner">
        <div className="card-front">
          <div className="card-image">
            <img src={image} alt={title} />
          </div>
          <div className="card-content">
            <div className="card-title">{title}</div>
            {name && <div className="card-name">{name}</div>}
          </div>
        </div>
        <div className="card-back">
          <div className="card-content">
            <div className="card-subtitle">{subtitle}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;